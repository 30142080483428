<i18n locale="th" lang="yaml" >
page.title : "รายงานการ Claim"
page.description : "สรุปรายงานการขอ Claim ของทางศูนย์บริการ"

claim.search.keyword : "ค้นหา"
claim.search.keyword.placeholder : "เลขใบงาน, เลขเคลม หรือ S/N"
claim.search.company : "ศูนย์บริการ"
claim.search.company.placeholder : "@:common.search.view_all"
claim.search.claim_status_mode : "สถานะการเคลม"
claim.search.claim_status_mode.placeholder : "@:common.search.view_all"
claim.search.payment_request : "สถานะการเบิก"
claim.search.payment_request.placeholder : "@:common.search.view_all"

claim.search.submit.label : "เรียกดู"

enum.claim_status_mode.prepare : "เตรียมยื่น"
enum.claim_status_mode.wait-decision : "รออนุมัติ"
enum.claim_status_mode.decision : "ตอบกลับแล้ว"

enum.claim_payment_request.none : "ยังไม่เบิก"
enum.claim_payment_request.request : "ยื่นเบิกแล้ว"
enum.claim_payment_request.complete : "ได้รับเงินแล้ว"

claim.table.service_no : "ใบงาน"
claim.table.drone : "Drone"
claim.table.drone.display : "{serialNo}"
claim.table.company : "ศูนย์บริการ"
claim.table.service : "ข้อมูลใบงาน"
claim.table.service.open_date : "วันรับเรื่อง"
claim.table.service.status : "สถานะ"
claim.table.service.payment_status : "รับเงินจากลูกค้า"
claim.table.claim : "ข้อมูลการเคลม"
claim.table.claim.status : "สถานะ"
claim.table.claim.requested_datetime : "วันที่ยื่น"
claim.table.claim.decision_datetime : "วันที่ตอบ"
claim.table.claim_payment : "ข้อมูลการเบิก"
claim.table.claim_payment.total_claim_fee : "ค่าเคลม"
claim.table.claim_payment.payment_request : "สถานะการเบิก"
claim.table.claim_payment.payment_requested_date : "วันที่เบิก"
claim.table.claim_payment.payment_completed_date : "วันที่รับเงิน"
</i18n>
<template>
	<div class="page page-padding">
		<my-page-header :title="$t('page.title')" :description="$t('page.description')" />
		<div class="myform-action-pane">
			<a-form layout="inline">
				<a-form-item :label="$t('claim.search.keyword')">
					<a-input v-model="filter.keyword"
						style="width : 200px;"
						:placeholder="$t('claim.search.keyword.placeholder')"
						allow-clear >
						<a-icon slot="suffix" type="search" />
					</a-input>
				</a-form-item>

				<a-form-item :label="$t('claim.search.company')">
					<CompanyMultipleSelect
						allow-clear
						:placeholder="$t('claim.search.company.placeholder')"
						@change="handleCompanyChange"/>
				</a-form-item>

				<a-form-item :label="$t('claim.search.claim_status_mode')">
					<a-select
						class="claim-select"
						:get-popup-container="getPopupContainer"
						:placeholder="$t('claim.search.claim_status_mode.placeholder')"
						:options="claimStatusModeOptions"
						allow-clear
						:default-value="filter.claim_status_mode"
						@change="handleClaimStatusChange"/>
				</a-form-item>

				<a-form-item :label="$t('claim.search.payment_request')">
					<a-select
						class="claim-select"
						:get-popup-container="getPopupContainer"
						:placeholder="$t('claim.search.payment_request.placeholder')"
						:options="paymentRequestOptions"
						allow-clear
						@change="handlePaymentRequestChange"/>
				</a-form-item>
			</a-form>
		</div>

		<a-card
			:bordered="false">
			<a-table
				:loading="loading"
				:columns="claimColumns"
				:data-source="serviceDataList"
				bordered
				size="small"
				:pagination="pagination"
				:row-key="record => record.service.id"
				class="mytable mytable-scroll claim-table"
				@change="handleTableChange">
				<span slot="serviceNoRender" slot-scope="service">
					<my-router-link name="service/claim" :param-value="service.id">
						{{service.service_no}}
					</my-router-link>
				</span>
				<span slot="droneRender" slot-scope="drone">
					<my-router-link name="drone/view" :param-value="drone.serial_no" param-name="serial_no">
						{{$t('claim.table.drone.display',{ name: drone.name,serialNo: drone.serial_no })}}
					</my-router-link>
				</span>
				<span slot="companyRender" slot-scope="company">
					{{company.name}}
				</span>
				<span slot="serviceStatusRender" slot-scope="service">
					<StatusDot
						:status="$tenum('service.status',service.status)"
						:description="$tenum('service.status',service.status + '.description')"
						:reason="service.status_reason"
						:icon-mode="lookupStatusDot('service',service.status)"/>
				</span>
				<span slot="paymentStatusRender" slot-scope="payment">
					<StatusDot
						:status="$tenum('service.payment.status',payment.status)"
						:icon-mode="lookupStatusDot('payment',payment.status)"/>
				</span>
				<span slot="claimStatusRender" slot-scope="claimRequest">
					<StatusDot
						:status="$tenum('claim.status',claimRequest.status)"
						:description="$tenum('claim.status',claimRequest.status + '.description')"
						:icon-mode="lookupStatusDot('claim',claimRequest.status)"/>
				</span>

				<span slot="claimPaymentStatusRender" slot-scope="claimRequest">
					<StatusDot
						:status="$tenum('claim.payment_request',claimRequest.payment_request)"
						:icon-mode="lookupStatusDot('payment_request',claimRequest.payment_request)"/>
				</span>
				<span slot="dateRender" slot-scope="dateString">
					{{dateString | date}}
				</span>
				<span slot="priceRender" slot-scope="price">
					{{price | price(true)}}
				</span>
			</a-table>
		</a-card>
	</div>
</template>

<script>
import LoginInfoMixin from "@mixins/LoginInfoMixin.vue"
import PopupMixin from "@mixins/PopupMixin.vue"
import CompanyMultipleSelect from "@components/company/CompanyMultipleSelect.vue"
import {Table,Select} from "ant-design-vue"
import axios from "axios"
import ApiError from "@utils/errors/ApiError"
import _debounce from "lodash/debounce"
import {getEnumSelectOptions} from "@utils/formUtil"
import {fulfillServiceCardData} from "@utils/objectUtil"
import {lookupStatusDotMode} from "@utils/serviceUtil"
import StatusDot from "@components/common/StatusDot.vue"
import _isEqual from "lodash/isEqual"
export default {
	components : {
		"a-table" : Table, "a-select" : Select,
		CompanyMultipleSelect, StatusDot,
	} ,
	mixins : [LoginInfoMixin,PopupMixin] ,
	page() {
		return {
			title : this.$t('page.title')
		}
	} ,
	data() {
		return {
			loading : false,
			serviceDataList : [],
			pagination : {
				pageSize : 10 ,
				current : 1,
				total : 0,
				hideOnSinglePage : true,
			} ,
			sort : {
				field : 'updated_datetime',
				order : 'descend',
			} ,
			filter : {
				company_id : undefined,
				keyword : undefined,
				claim_status_mode : undefined,
				payment_request : undefined,
			}
		}
	} ,
	computed : {
		claimStatusModeOptions() {
			return getEnumSelectOptions(this,'claim_status_mode',['prepare','wait-decision','decision'])
		} ,
		paymentRequestOptions() {
			return getEnumSelectOptions(this,'claim_payment_request',['none','request','complete'])
		} ,
		claimColumns() {
			const columns = [
				{
					title : this.$t('claim.table.service_no') ,
					key : 'service_no' ,
					sorter : true,
					dataIndex : 'service' ,
					scopedSlots: { customRender: 'serviceNoRender' },
					class : 'col-service-no' ,
				} ,
				{
					title : this.$t('claim.table.drone') ,
					key : 'drone' ,
					sorter : true,
					dataIndex : 'drone' ,
					scopedSlots : { customRender : 'droneRender'} ,
					class : 'col-drone' ,
				} ,
				{
					title : this.$t('claim.table.company') ,
					key : 'company' ,
					dataIndex : 'company' ,
					scopedSlots : { customRender : 'companyRender'} ,
					class : 'col-company'
				} ,
				{
					title : this.$t('claim.table.service') ,
					key : 'service' ,
					children : [
						{
							title : this.$t('claim.table.service.open_date') ,
							key : 'open_date' ,
							dataIndex : 'service.open_date',
							sorter : true,
							scopedSlots : { customRender : 'dateRender'} ,
							class : 'col-date' ,
						} ,
						{
							title : this.$t('claim.table.service.status') ,
							key : 'status' ,
							dataIndex : 'service',
							scopedSlots : { customRender : 'serviceStatusRender'} ,
							class : 'col-status' ,
						} ,
						{
							title : this.$t('claim.table.service.payment_status') ,
							key : 'payment_status' ,
							dataIndex : 'service.otherDetailsData.payment_status',
							scopedSlots : { customRender : 'paymentStatusRender'} ,
							class : 'col-status' ,
						}
					]
				} ,
				{
					title : this.$t('claim.table.claim') ,
					key : 'claim' ,
					children : [
						{
							title : this.$t('claim.table.claim.status') ,
							key : 'claim.status' ,
							dataIndex : 'service.otherDetailsData.claim_request' ,
							scopedSlots : {customRender : 'claimStatusRender'} ,
							class : 'col-status' ,
						} ,
						{
							title : this.$t('claim.table.claim.requested_datetime') ,
							key : 'claim.requested_datetime' ,
							sorter : true,
							dataIndex : 'service.otherDetailsData.claim_request.requested_datetime' ,
							scopedSlots : {customRender : 'dateRender'} ,
							class : 'col-date' ,
						} ,
						{
							title : this.$t('claim.table.claim.decision_datetime') ,
							key : 'claim.decision_datetime' ,
							sorter : true,
							dataIndex : 'service.otherDetailsData.claim_request.decision_datetime' ,
							scopedSlots : {customRender : 'dateRender'} ,
							class : 'col-date' ,
						}
					]
				} ,
				{
					title : this.$t('claim.table.claim_payment') ,
					key : 'claim_payment' ,
					children : [
						{
							title : this.$t('claim.table.claim_payment.payment_request') ,
							key : 'claim.payment_request' ,
							dataIndex : 'service.otherDetailsData.claim_request' ,
							scopedSlots : {customRender : 'claimPaymentStatusRender'} ,
							class : 'col-status' ,
						} ,
						{
							title : this.$t('claim.table.claim_payment.total_claim_fee') ,
							key : 'claim.total_claim_fee' ,
							dataIndex : 'service.otherDetailsData.claim_request.total_claim_fee' ,
							sorter : true,
							scopedSlots : {customRender : 'priceRender'} ,
							class : 'col-price' ,
						} ,
						{
							title : this.$t('claim.table.claim_payment.payment_requested_date') ,
							key : 'claim.payment_requested_date' ,
							dataIndex : 'service.otherDetailsData.claim_request.payment_requested_date' ,
							sorter : true,
							scopedSlots : {customRender : 'dateRender'},
							class : 'col-date' ,
						} ,
						{
							title : this.$t('claim.table.claim_payment.payment_completed_date') ,
							key : 'claim.payment_completed_date' ,
							dataIndex : 'service.otherDetailsData.claim_request.payment_completed_date' ,
							sorter : true,
							scopedSlots : {customRender : 'dateRender'},
							class : 'col-date' ,
						}
					]
				}
			];
			return columns
		} ,
	},
	watch: {
		filter: {
			handler() {
				this.debounceSearchChange()
			},
			deep: true,
		},
	},
	created() {
		if (this.$route.query.claim_mode) {
			if (this.claimStatusModeOptions.findIndex((status) => status.value==this.$route.query.claim_mode) >= 0)
				this.filter.claim_status_mode = this.$route.query.claim_mode
		}
		if (this.$route.query.payment_request) {
			if (this.paymentRequestOptions.findIndex((status) => status.value==this.$route.query.payment_request) >= 0)
				this.filter.payment_request = this.$route.query.payment_request
		}
		this.debounceSearchChange = _debounce(this.handleSearchChange,300);
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		lookupStatusDot(name,status) {
			return lookupStatusDotMode(name,status)
		} ,
		handleSearchChange() {
			this.pagination.current = 1;
			this.fetchData()
		} ,
		fetchData(toPage=null) {
			if (toPage === null)
				toPage = this.pagination.current
			const search = {
				page_size : this.pagination.pageSize ,
				current_page : toPage,
				sort_field : this.sort.field ,
				sort_order : this.sort.order ,
			}
			this.loading = true;
			const filter = { ...this.filter }
			axios.get("/api/services/search-claim-request-report",{params : {search,filter}}).then((response)=>{
				const page = response.data.data.pagination;
				this.dataBucket = response.data.data.bucket

				this.serviceDataList = response.data.data.services.map((s)=>{
					return fulfillServiceCardData(s,this.dataBucket)
				})

				this.pagination.current = toPage
				this.pagination.total = page.total
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=>{
				this.loading = false
			})
		} ,
		handleTableChange(pagination,filters,sorter) {
			this.sort.field = sorter.columnKey;
			this.sort.order = sorter.order;
			this.fetchData(pagination.current);
		} ,
		handleCompanyChange(companyId) {
			if (!_isEqual(companyId, this.filter.company_id)) {
				this.filter.company_id = companyId
			}
		} ,
		handleClaimStatusChange(claimStatus) {
			if (!_isEqual(claimStatus, this.filter.claim_status_mode)) {
				this.filter.claim_status_mode = claimStatus
			}
		} ,
		handlePaymentRequestChange(paymentRequest) {
			if (!_isEqual(paymentRequest, this.filter.payment_request)) {
				this.filter.payment_request = paymentRequest
			}
		}
	}
}
</script>

<style lang="less" scoped>
.claim-select {
	width : 150px;
}
.claim-table::v-deep {
	min-width : 1450px;
	th {
		text-align: center;
	}
	.col-company {
		width : 120px;
	}
	.col-service-no {
		width : 150px;
	}
	.col-drone {

	}
	.col-price {
		width : 100px;
	}
	.col-date {
		width : 125px;
	}
	.col-status {
		width : 125px;
	}

	.mobile & .ant-table ,
	.tablet & .ant-table {
		font-size : 0.9em;
		.col-company {
			width : 100px;
		}
		.col-service-no {
			width : 135px;
		}
		.col-price {
			width : 80px;
		}

	}

	.ant-table-tbody {
		.col-status , .col-date {
			text-align : center;
			font-size : 0.95em;
		}
		.col-price {
			text-align : right;
			font-size : 0.95em;
		}
	}
}

</style>
